import React, { useState, useEffect, useMemo, useContext } from 'react';
import Sticky from 'react-stickynode';
import _throttle from 'lodash/throttle';
import cx from 'classnames';
import {
  FeatureFlagsProductType,
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  TertiaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { clientIsBaby } from '@zola-helpers/client/dist/es/util/clientIsBaby';
import {
  getAlbumsNavItems,
  getAlbumsSecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/albumsNavData';
import {
  getGuestListNavItems,
  getGuestListSecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/guestListNavData';
import {
  getInvitesNavItems,
  getInvitesSecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/invitesNavData';
import { getWebsiteSecondaryNavItems } from '@zola-helpers/client/dist/es/nav/websiteNavData';
import {
  getPostAuthNav3Height,
  getPostAuthNav3HeightMobile,
} from '@zola-helpers/client/dist/es/nav/getPostAuthNav3Height';
import { publishFeatureFlagOverrides } from '@zola-helpers/client/dist/es/featureFlags';
import { notDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import { NAV_LOADED } from '~/constants/navConstants';
import { DeviceContext } from '~/contexts/DeviceContext';
import { FLAGS } from '~/util/featureFlags';
import experimentFlags, {
  HOLIDAY_CARDS,
  NEW_PAPER_TYPE_NAPKINS,
  NEW_PAPER_TYPE_SIGNAGE,
  NEW_PAPER_TYPE_STICKERS,
} from '~/util/experimentFlags';
import { emitUpdateNavDataEvent, getNavHeight } from '~/components/common/header/helpers';
import { isPostAuthNav3Desktop, isPostAuthNav3MobileOrDesktop } from '~/util/isPostAuthNav3Active';
import PreauthNavSkeletonLoader from '@zola/zola-ui/src/components/PreauthNavSkeletonLoader';
import UnifiedNavHtmlLoader from './UnifiedNavHtmlLoader';
import type { UnifiedNavDispatchProps, UnifiedNavStateProps } from './types';

import { SecondaryType } from './types';

import styles from './styles.module.less';

type UnifiedNavProps = UnifiedNavStateProps &
  UnifiedNavDispatchProps & {
    className?: string;
    showPromo?: boolean;
    activePrimaryLink?: PrimaryNavIdentifier;
    activeSecondaryLink?: SecondaryNavIdentifier;
    activeTertiaryLink?: TertiaryNavIdentifier;
    disablePrimaryNavCollapse?: boolean;
    disableBannerCollapse?: boolean;
    enableSticky?: boolean;
    albumEvent?: string;
    isHidden?: boolean;
    navItems?: SecondaryNavItem[];
    secondaryType?: SecondaryType | string;
    emitDirectionEvents?: boolean;
    hideTertiaryNav?: boolean;
    isPostAuthSSR?: boolean;
    initialHeight?: number;
    disableNavCollapse?: boolean;
  };

const UnifiedNav: React.FC<UnifiedNavProps> = ({
  className,
  onGetNav,
  fetchUserContext,
  showPromo,
  activePrimaryLink,
  activeSecondaryLink,
  activeTertiaryLink,
  disablePrimaryNavCollapse,
  disableBannerCollapse,
  enableSticky = true,
  isHidden,
  userContext,
  navItems,
  secondaryType,
  emitDirectionEvents,
  hideTertiaryNav = false,
  isPostAuthSSR = false,
  initialHeight = 60, // default to mobile height
  disableNavCollapse = false,
}) => {
  const [height, setHeight] = useState<number>(initialHeight);
  const [nav, setNav] = useState<string>('');
  const { device } = useContext(DeviceContext);
  const isNotDesktop = typeof window !== 'undefined' ? notDesktop() : device?.notDesktop();
  const isBaby = typeof window !== 'undefined' ? clientIsBaby() : false;
  const isPostAuthNav3Active = isPostAuthNav3MobileOrDesktop(userContext) || isPostAuthSSR;
  const showHolidayCards =
    typeof window !== 'undefined' ? experimentFlags.getFlag(HOLIDAY_CARDS, userContext) : false;
  const enableNapkins =
    typeof window !== 'undefined'
      ? experimentFlags.getFlag(NEW_PAPER_TYPE_NAPKINS, userContext)
      : false;
  const enableSignage =
    typeof window !== 'undefined'
      ? experimentFlags.getFlag(NEW_PAPER_TYPE_SIGNAGE, userContext)
      : false;
  const enableStickers =
    typeof window !== 'undefined'
      ? experimentFlags.getFlag(NEW_PAPER_TYPE_STICKERS, userContext)
      : false;

  // useMemo so that the event listener hook uses correct ref equality
  const secondaryData = useMemo(() => {
    let data;
    if (isPostAuthNav3Active) {
      if (secondaryType === SecondaryType.SECONDARY_NAV_INVITES) {
        data = getInvitesSecondaryNavItems(userContext);
      } else if (secondaryType === SecondaryType.SECONDARY_NAV_GUESTLIST) {
        data = getGuestListSecondaryNavItems(userContext);
      } else if (secondaryType === SecondaryType.SECONDARY_NAV_WEBSITE && !isNotDesktop) {
        data = getWebsiteSecondaryNavItems(userContext);
      } else if (secondaryType === SecondaryType.SECONDARY_NAV_ALBUMS) {
        data = getAlbumsSecondaryNavItems(userContext);
      }
    } else if (navItems) {
      data = navItems;
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_INVITES) {
      data = getInvitesNavItems({
        forTertiaryNav: false,
        hideNav: hideTertiaryNav,
        isMobile: isNotDesktop,
        showHolidayCards,
        enabledNewPaper: {
          napkins: enableNapkins,
          signage: enableSignage,
          stickers: enableStickers,
        },
      });
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_GUESTLIST) {
      data = getGuestListSecondaryNavItems(userContext);
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_WEBSITE) {
      data = getWebsiteSecondaryNavItems(userContext);
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_ALBUMS) {
      data = getAlbumsNavItems(userContext);
    }
    return data;
  }, [
    hideTertiaryNav,
    isNotDesktop,
    isPostAuthNav3Active,
    navItems,
    secondaryType,
    userContext,
    showHolidayCards,
    enableNapkins,
    enableSignage,
    enableStickers,
  ]);

  const tertiaryData = useMemo(() => {
    let data;

    if (isPostAuthNav3Active) {
      if (secondaryType === SecondaryType.SECONDARY_NAV_INVITES) {
        data = getInvitesNavItems({
          forTertiaryNav: true,
          hideNav: hideTertiaryNav,
          isMobile: isNotDesktop,
          showHolidayCards,
          enabledNewPaper: {
            napkins: enableNapkins,
            signage: enableSignage,
            stickers: enableStickers,
          },
        });
      } else if (
        secondaryType === SecondaryType.SECONDARY_NAV_GUESTLIST &&
        activeSecondaryLink === SecondaryNavIdentifier.ALL_GUESTS
      ) {
        data = getGuestListNavItems(hideTertiaryNav);
      }
    }

    return data;
  }, [
    activeSecondaryLink,
    hideTertiaryNav,
    isNotDesktop,
    isPostAuthNav3Active,
    secondaryType,
    showHolidayCards,
    enableNapkins,
    enableSignage,
    enableStickers,
  ]);

  const disableSecondary = !secondaryData;

  const refreshNavHeight = _throttle(() => {
    const hasSecondaryData = secondaryData ? secondaryData.length > 0 : false;
    const hasTertiaryData = tertiaryData ? tertiaryData?.length > 0 : false;

    // pre-auth & baby
    let newHeight = getNavHeight(
      disableSecondary,
      userContext ? Boolean(userContext.is_guest) : true,
      isBaby
    );

    if (isPostAuthNav3Desktop(userContext) && !isBaby) {
      // post-auth desktop
      newHeight = getPostAuthNav3Height({
        hasSecondaryData,
        hasTertiaryData,
        hasAnnouncementBar: true,
        isMultiAnnouncementBar: true,
      });
    } else if (isPostAuthNav3Active && !isBaby) {
      // post-auth tablet & mobile
      newHeight = getPostAuthNav3HeightMobile({ hasSecondaryData, hasTertiaryData });
    }

    if (height !== newHeight) {
      setHeight(newHeight);
    }
  }, 200);

  useEffect(() => {
    const handleInitNavData = (): void => {
      emitUpdateNavDataEvent({
        userContext,
        secondaryData,
        activePrimaryLink,
        activeSecondaryLink,
        activeTertiaryLink,
        disablePrimaryNavCollapse,
        disableBannerCollapse,
        emitDirectionEvents,
        tertiaryData,
        disableNavCollapse,
      });
      publishFeatureFlagOverrides(FLAGS, FeatureFlagsProductType.WEB_WEDDING);
    };
    if (!nav && onGetNav) {
      onGetNav(isHidden)
        .then((res) => {
          setNav(res);
          refreshNavHeight();
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }

    window.addEventListener(NAV_LOADED, handleInitNavData);
    window.addEventListener('resize', refreshNavHeight);

    return (): void => {
      window.removeEventListener(NAV_LOADED, handleInitNavData);
      window.removeEventListener('resize', refreshNavHeight);
    };
  }, [
    secondaryData,
    userContext,
    activeSecondaryLink,
    nav,
    onGetNav,
    refreshNavHeight,
    activePrimaryLink,
    disablePrimaryNavCollapse,
    disableBannerCollapse,
    showPromo,
    emitDirectionEvents,
    tertiaryData,
    activeTertiaryLink,
    isNotDesktop,
    disableNavCollapse,
    isHidden,
  ]);

  // If userContext changes after the nav initialization, update the nav
  useEffect(() => {
    if (!userContext) {
      fetchUserContext();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      emitUpdateNavDataEvent({ userContext } as any);
    }
  }, [userContext, fetchUserContext]);

  // If secondaryData changes after the nav initialization, update the nav
  useEffect(() => {
    if (!secondaryData) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitUpdateNavDataEvent({ secondaryData } as any);
  }, [secondaryData]);

  // If tertiaryData changes after the nav initialization, update the nav
  useEffect(() => {
    if (!tertiaryData) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitUpdateNavDataEvent({ tertiaryData } as any);
  }, [tertiaryData]);

  const defaultStyles: React.CSSProperties = {
    height,
    transform: 'none',
  };

  // The nav needs to be in the DOM _and_ not be "display:none" in order to
  // show the cart modal (or any nav modal).  Changing this to "display: none"
  // and then viewing the cart from a V2 wedding website (where this nav is
  // hidden) causes a focus trap error in the nav:
  // focus-trap.esm.js:246 Uncaught Error: Your focus-trap must have at least one container with at least one tabbable node in it at all times
  // react-dom.development.js:19527 The above error occurred in the <FocusTrap> component:
  //   in FocusTrap (created by ModalRoot)
  //   in div (created by ModalRoot)
  //   in ModalRoot (created by ConnectFunction)
  const hiddenStyles: React.CSSProperties = isHidden
    ? { position: 'absolute', left: '-99999px', zIndex: 2001 } // zIndex to help with double modal issues
    : {};

  return (
    <>
      <div
        className={cx('unified-nav', className, {
          [styles.unifiedNav]: secondaryType === SecondaryType.SECONDARY_NAV_INVITES,
        })}
        style={{ ...defaultStyles, ...hiddenStyles }}
      >
        <PreauthNavSkeletonLoader showPromoBar={showPromo} showSecondaryRow={!disableSecondary} />
        <Sticky innerZ={500} enableTransforms={false} enabled={enableSticky}>
          <UnifiedNavHtmlLoader html={nav} />
        </Sticky>
      </div>
    </>
  );
};

export default UnifiedNav;
