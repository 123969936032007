import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import NAV_DATA, {
  InitNavDataEventPayload,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { UserContext } from '@zola-helpers/client/dist/es/@types';

import { isPostAuthNav3MobileOrDesktop } from '~/util/isPostAuthNav3Active';
import { SecondaryType } from './UnifiedNav/types';

const {
  EVENTS: { INIT_NAV_DATA },
} = NAV_DATA;

export const emitUpdateNavDataEvent = ({
  userContext,
  secondaryData,
  activePrimaryLink,
  activeSecondaryLink,
  activeTertiaryLink,
  disablePrimaryNavCollapse,
  disableBannerCollapse,
  emitDirectionEvents,
  tertiaryData,
  disableNavCollapse,
}: Partial<InitNavDataEventPayload>): void => {
  window.dispatchEvent(
    new CustomEvent<Partial<InitNavDataEventPayload>>(INIT_NAV_DATA, {
      detail: {
        userContext,
        secondaryData,
        activePrimaryLink,
        activeSecondaryLink,
        activeTertiaryLink,
        disablePrimaryNavCollapse,
        disableBannerCollapse,
        emitDirectionEvents,
        tertiaryData,
        disableNavCollapse,
      },
    })
  );
};

export const shouldShowWebsiteSecondaryNav = (userContext: UserContext) => {
  if (isPostAuthNav3MobileOrDesktop(userContext)) {
    return userContext.has_website;
  }

  return false;
};

export const getWebsiteSecondaryNavType = (userContext: UserContext) => {
  return shouldShowWebsiteSecondaryNav(userContext) ? SecondaryType.SECONDARY_NAV_WEBSITE : '';
};

export const getDesktopNavHeight = (
  disableSecondary: boolean,
  isGuest: boolean,
  isBaby?: boolean
): number => {
  // Pre-auth nav is larger
  let heightWithSecondary = isGuest ? 189 : 140;
  let heightWithoutSecondary = isGuest ? 133 : 104;
  if (isBaby) {
    heightWithSecondary = 184;
    heightWithoutSecondary = 128;
  }

  return disableSecondary ? heightWithoutSecondary : heightWithSecondary;
};

export const getNavHeight = (
  disableSecondary: boolean,
  isGuest: boolean,
  isBaby?: boolean
): number => {
  if (isDesktop()) {
    return getDesktopNavHeight(disableSecondary, isGuest, isBaby);
  }

  let height = disableSecondary ? 56 : 108;
  if (isBaby) {
    height = disableSecondary ? 96 : 136;
  }

  return height;
};

export default {
  emitUpdateNavDataEvent,
  getNavHeight,
};
