import type { UserContext } from '~/actions/UserActions';

export enum SecondaryType {
  SECONDARY_NAV_WEBSITE = 'website',
  SECONDARY_NAV_INVITES = 'invites',
  SECONDARY_NAV_GUESTLIST = 'guestlist',
  SECONDARY_NAV_ALBUMS = 'albums',
}

export type UnifiedNavStateProps = {
  userContext: UserContext;
};

export type UnifiedNavDispatchProps = {
  fetchUserContext: () => unknown;
  onGetNav: (isHidden?: boolean) => Promise<string>;
};
