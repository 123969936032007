import { connect } from 'react-redux';

import { getUserContext as getUserContextSelector } from '~/selectors/user/userSelectors';
import { getBabyNav, getNav } from '~/actions/NavActions';
import { getUserContext } from '~/actions/UserActions';
import { clientIsBaby } from '@zola-helpers/client/dist/es/util/clientIsBaby';

import type { RootState } from '~/reducers/index';
import type { AppDispatch } from '~/reducers/useAppDispatch';
import UnifiedNav from './UnifiedNav';

import type { UnifiedNavDispatchProps, UnifiedNavStateProps } from './types';

const mapStateToProps = (state: RootState): UnifiedNavStateProps => ({
  userContext: getUserContextSelector(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): UnifiedNavDispatchProps => ({
  fetchUserContext: (): unknown => dispatch(getUserContext()),
  onGetNav: (isHidden = false): Promise<string> =>
    dispatch(clientIsBaby() && !isHidden ? getBabyNav() : getNav()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnifiedNav);
